import { useAuthStore } from '~/stores/auth'

export default defineNuxtRouteMiddleware(() => {
  const store = useAuthStore()
  if (!store.canCreatePO) {
    return navigateTo({ name: 'dashboard' })
  }

  return null
})
